@import '../mixin/media';
@import '../mixin/resize';
@import '../variable';

@mixin parallax_keyframe($name, $begin-percent: 0%, $end-percent: 100%, $properties: ()) {
  @keyframes parallax-#{$name} {
    @if $begin-percent != 0% {
      0% {
        @each $prop in $properties {
          #{$prop}: var(--parallax-begin-#{$prop});
        }
      }
    }
    #{$begin-percent} {
      @each $prop in $properties {
        #{$prop}: var(--parallax-begin-#{$prop});
      }
    }
    #{$end-percent} {
      @each $prop, $value in $properties {
        #{$prop}: var(--parallax-end-#{$prop});
      }
    }
    @if $end-percent != 100% {
      100% {
        @each $prop, $value in $properties {
          #{$prop}: var(--parallax-end-#{$prop});
        }
      }
    }
  }
}

// parallax_element common use cases:
// Timeline: 
// - `scroll(root y)` - General root scroll y position tracking.
// - `view(block)` - Tracking what is on screen, best for different zoom levels. 
//   - `animation-range-start: $page_height;` for beginning animation at the top of the screen
@mixin parallax_element($name, $timing_function, $timeline, $properties: ()) {
  animation: parallax-#{$name} #{$timing_function};
  animation-timeline: #{$timeline};
  animation-fill-mode: both;

  @each $prop, $values in $properties {
    --parallax-begin-#{$prop}: #{nth($values, 1)};
    --parallax-end-#{$prop}: #{nth($values, 2)};
  }
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 491px; // maintain view()-based animation speed consistency by not changing height instead of using 'bottom'
  // max-height: $page_height_small;
  background-image: url('/asset/image/interface/background/index/level_oldharbor.png');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
}

// @media (prefers-reduced-motion: no-preference) {
@supports (animation-timeline: view()) {
  @include parallax_keyframe(backdrop, 0%, 100%, (
    opacity, transform
    // bottom // prevent going past bottom of page
  ));
  
  body::before {
    // $translate_y__distance: 25rem;
    $translate_y__distance: 400px; // cannot exceed image height!

    @include parallax_element(backdrop, linear, view(block), (
      opacity: (1, 0),
      transform: (translateY(0rem), translateY($translate_y__distance))
      // background-position-y: (0rem, $translate_y__distance)
      // bottom: (0rem, $translate_y__distance) // prevent going past bottom of page
    ));

    animation-range-start: $page_height;
  }
}
// }

// body::before, 
// #title, 
// #title > * 
// {
//   border: 2px yellow solid;
// }

main section {
  position: relative;
}

@keyframes animate-intro {
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

#title {
  /* min-height: 24rem; */
  padding-top: 4rem;
  padding-bottom: 4rem;

  background: radial-gradient(#ffffff 30%, transparent 70%);
  background: radial-gradient(#ffffff80 30%, transparent 70%);

  img {
    width: initial;
    max-width: 100%;

    box-shadow: 0px 8px 16px #00000044;
    border-radius: 50%;
  }
}

// title blur radius

.backdropfilter #title {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    backdrop-filter: blur(4px);
    
    mask-image: radial-gradient(closest-side, black, transparent);
    -webkit-mask-image: radial-gradient(closest-side, black, transparent);

    z-index: 0;
  }

  & > * {
    z-index: 1;
    position: relative;
  }
}

// @include media('>phone-large') {
@supports (animation-timeline: view()) {
  @include parallax_keyframe(title__section, 0%, 100%, (
    transform
  ));
  
  @include parallax_keyframe(title__header, 0%, 100%, (
    opacity
  ));

  $translate_y__distance: 5rem;
  
  #title {
    @include parallax_element(title__section, cubic-bezier(0.75, 0.75, 0.5, 1), view(), (
      transform: (translateY(0rem), translateY($translate_y__distance))
    ));
    
    animation-range-start: calc($page_height - 77px);
    animation-range-end: calc($page_height + 30%);

    overflow: initial;

    h1 {
      @include parallax_element(title__header, cubic-bezier(0.75, 0.75, 0.5, 1), view(), (
        opacity: (1, 0)
      ));

      // animation-range-start: calc(115vh);
      // animation-range-end: calc($page_height - 100%);

      // animation-range: cover 85vh cover 95vh;

      animation-range-start: calc($page_height + ($translate_y__distance));
      // animation-range-end: calc($page_height + ($translate_y__distance + 7.5%));
      animation-range-end: calc($page_height + ($translate_y__distance * 2.0));
    }
  }
  
  // @include parallax_keyframe(header__nav, 0%, 100%, (
  //   opacity
  // ));

  // $translate_y__distance__header: 50rem;
  
  // #nav_header {
  //   @include parallax_element(header__nav, cubic-bezier(0.75, 0.75, 0.5, 1), view(), (
  //     opacity: (0, 1)
  //   ));

  //   animation-range-start: calc($page_height + ($translate_y__distance__header));
  //   // animation-range-end: calc($page_height + ($translate_y__distance__header + 7.5%));
  //   animation-range-end: calc($page_height + ($translate_y__distance__header * 2.0));

  //   position: fixed !important;
  //   transform: none !important;
  // }
}
// }

.text--intro {
  color: $theme_color;
  text-shadow: 0px 0.1em 2px rgba(0, 0, 0, 0.7);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

html.backgroundcliptext .text--intro, html.backgroundcliptext .text--intro::after {
  background: #fde98f;
  background: linear-gradient(to right, #fde98f, #eed460);

  // filter: drop-shadow(0.05em 0.05em 0.05em #000)
  filter: drop-shadow(0.15rem 0.15rem 0.15rem #000)
}

html.backgroundcliptext .text--intro {
  color: transparent;
  -webkit-background-clip: text;
  text-shadow: none;
}

html.backgroundcliptext .text--intro::after {
  opacity: 0.5;
}

// hide navbar on index page

#nav_header {
  height: 0px !important;
  display: none !important;
}

#main {
  padding-top: 0px !important;
}

// #gallery_preview {
//   transform: translateX(0%);
// }

// @include media(">desktop-small") {
//   #gallery_preview {
//     transform: translateX(12.5%);
//   }
// }
